<template>
  <div id="app">
    <default>
      <router-view/>
    </default>
  </div>
</template>

<script>
export default {
  components: {
    default: () => import('@/plugins/visivig/layout/layout-default/default.vue')
  }
}
</script>