import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
        name: 'home',
		component: () =>
			import('./plugins/visivig/pages/page-home.vue')
	},
	{
		path: '/blogs',
        name: 'page-blogs',
		component: () =>
			import('./plugins/visivig/pages/page-blogs.vue')
	},
	{
		path: '/blogs/:slug',
        name: 'page-blog',
		component: () =>
			import('./plugins/visivig/pages/page-blog.vue')
	},
	{
		path: '*',
        name: 'page-404',
		component: () =>
			import('./plugins/visivig/pages/page-404.vue')
	},
	{
		path: '/agencies',
        name: 'page-agencies',
		component: () =>
			import('./plugins/visivig/pages/page-agencies.vue')
	},
	{
		path: '/tours',
        name: 'page-tours',
		component: () =>
			import('./plugins/visivig/pages/page-tours.vue')
	},
	{
		path: '/tours/:slug',
        name: 'page-tour',
		component: () =>
			import('./plugins/visivig/pages/page-tour.vue')
	},
	{
		path: '/accomodations',
        name: 'page-accomodations',
		component: () =>
			import('./plugins/visivig/pages/page-accomodations.vue')
	},
	{
		path: '/accomodations/:slug',
        name: 'page-accomodation',
		component: () =>
			import('./plugins/visivig/pages/page-accomodation.vue')
	},
]

const router = new VueRouter({
	mode: 'history',
	routes,
})

export default router
