import Vue from 'vue'
import App from './App.vue'
import router from './router'
import apiService from './plugins/_common/apiService'
import 'bootstrap/dist/css/bootstrap.css'
import './plugins/_theme/_style/style.css'
import store from './store'


Vue.config.productionTip = false

import AOS from 'aos'
import 'aos/dist/aos.css'

apiService.init()

new Vue({
    store,
    router,
	render: h => h(App),
    mounted() {
        AOS.init()
    },
}).$mount('#app')
